import { React, useEffect } from 'react'
import { Typography, TextField, Autocomplete, Dialog, DialogTitle, DialogContent, Stack, IconButton } from "@mui/material/"
import CloseIcon from '@mui/icons-material/Close'
import LoadingButton from '@mui/lab/LoadingButton'
import { Popper } from '@mui/material'
import { styled } from '@mui/material/styles'
import GetRoute from '../../GetRoute'

export default function SignInDialog({ client, loginState, setLoginState, initLoginState }) {
    const routeIP = GetRoute(window.location.hostname)
    const protocol = window.location.protocol

    const LightGreyPopper = styled(Popper)({
        '& .MuiAutocomplete-paper': {
          backgroundColor: '#E5E4E2', // Light grey color
        },
    })
    const closeSigninVisitorDialog = () => {
        setLoginState((prev) => (initLoginState))
    }

    const fetchContacts = async () => {
        try {
            await client.updateToken(5)
            const headers = { auth: client.token }

            const response = await fetch(`${protocol}//${routeIP}:8000/utils/visitorLoginContacts`,
                { headers }
            )

            if (!response.ok) {
                const error = await response.json()
                throw new Error(error.message)
            }
            
            const data = await response.json()
            setLoginState((prev) => ({...prev, availableContacts: data}))

        } catch (error) {
            alert(`Failed to fetch companies: ${error}`)
        }
    }

    useEffect(() => {
        fetchContacts()
        // eslint-disable-next-line
    },[])

    const signInVisitor = async () => {
        try {
            await client.updateToken(5)
            await fetch(`${protocol}//${routeIP}:8000/utils/signInVisitor`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: loginState.email,
                    contact: loginState.contact,
                    token: client.token
                })
            }).then((response) => {
                    if(!response.ok) {
                        throw new Error(response.message)
                    }
                    return response.json()
                }).then((data) => {
                    console.log(data['result'])
                    if (loginState.contact !== "Other" && data['result']) {
                        setLoginState((prev) => ({...prev, confirmationDialogToggle: data['result'], signInDialogToggle: false, message: "Your contact should be here to get you shortly."}))
                    } else if (loginState.contact === "Other" && data['result']) {
                        setLoginState((prev) => ({...prev, confirmationDialogToggle: data['result'], signInDialogToggle: false, message: ""}))
                    } else if (!data['result']) {
                        setLoginState((prev) => ({...prev, confirmationDialogToggle: !data['result'], signInDialogToggle: false, message: "An error occurred notifing you contact, but your sign in was successful. "}))
                    }
                })
        } catch (error) {
            alert(error)
        }
    }

    const checkContactValue = () => {
        if(loginState.contact === null) {
            setLoginState((prev) => ({...prev, contactErrorValue: true}))
            return false
        }
        return true
    }


    return (
        <Dialog
            open={loginState.signInDialogToggle}
            onClose={closeSigninVisitorDialog}
            fullWidth
            maxWidth="xs"
            sx={{
                '& .MuiDialog-container': {
                    alignItems: 'baseline', // Aligns the dialog near the top
                },
                '& .MuiDialog-paper': {
                    marginTop: 1, // Adds some top margin
                },
            }}
        >
            <DialogTitle sx={{ backgroundColor: '#0e4780', color: 'white' }}>
                Welcome back {loginState.visitorName}!
                <IconButton
                    size='small'
                    onClick={closeSigninVisitorDialog}
                    sx={{ float: 'right', color: 'white' }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers sx={{ padding: 4 }} >
                <Stack spacing={2}>
                    <Typography sx={{ fontSize: 20, paddingBottom: .5 }}>
                        Who are you meeting with today?
                    </Typography>
                    <Autocomplete
                        disablePortal
                        id="manager"
                        PopperComponent={LightGreyPopper}
                        value={loginState.contact}
                        options={loginState.availableContacts}
                        style={{ marginBottom: "20%" }}
                        onChange={(event, newValue) => {
                            setLoginState((prev) => ({...prev, contact: newValue}))
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                name="manager"
                                variant="outlined"
                                label="Manager"
                                error={loginState.contactErrorValue}
                                helperText={<span style={{ margin: 0, fontSize: 16 }}>Must select a manager from drop down</span>}
                            />
                        )}
                    />
                    <LoadingButton
                        loading={loginState.loadingButtonToggle}
                        id="submitButton"
                        sx={{ padding: 1.5, fontSize: 16, color:  'white', backgroundColor: '#0e4780', textTransform: 'none', 
                            '&:hover': { cursor: 'pointer', backgroundColor: '#0e4780' }, '& .MuiCircularProgress-root': { color: 'white' } }}
                        onClick={ async () => {
                            const button = document.querySelector("#submitButton")
                            button.disabled = true
                            setTimeout(() => button.disabled = false, 3000)
                            const isValid = checkContactValue()
                            if (isValid) {
                                setLoginState((prev) => ({...prev, loadingButtonToggle: true}))
                                // await new Promise(r => setTimeout(r, 1000))
                                await signInVisitor()
                                setLoginState((prev) => ({...prev, loadingButtonToggle: false}))
                            }
                        }}
                    >
                        Sign In
                    </LoadingButton>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}