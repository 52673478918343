import React from 'react';
import { useState, useEffect, useRef } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Paper, Grid, Typography, Box, FormControl, FormControlLabel, FormGroup, InputLabel, Select, MenuItem, Button, FormHelperText, Checkbox, Divider } from '@mui/material/'
import dayjs from 'dayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import LinearProgress from '@mui/material/LinearProgress'
import parseReportData from './ParseData/parseReportData'
import GenerateReport from './GenerateReport'
import CloseIcon from '@mui/icons-material/Close'
import { getMachineList, generateReportDataOptions } from './utils'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import GetRoute from '../GetRoute'

dayjs.extend(utc)
dayjs.extend(timezone)

const MenuProps = {
    PaperProps: {
        style: {
            // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            maxHeight: 400,
            width: 100,
        },
    },
}

const ReportGeneration = ({ client }) => {
    const reportRef = useRef()

    const initialState = {
        reportType: "",
        reportFetchResult: [],
        itemsToGet: [],
        startDate: dayjs().subtract(1, 'day'),
        endDate: dayjs(new Date()),
        reportErrorValue: false,
        itemsToGetErrorValue: false,
        linearProgressToggle: false,
        programTableToggle: false,
        errorTableToggle: false,
        dailyRecordsToggle: false,
        dailyRecordsBarGraph: false,
        dailyRecordHLATable: false,
        dailyRecordHLABarGraph: false,
        AverageOperatingTimePieChart: false,
        groupOverview: false,
        excludeWeekend: false,
        reportDialogToggle: false

    }

    const reportValuesInitState = {
        ScopeObject: {
            fetchDepartments: "Department Report",
            fetchMachines: "Machine Report",
            fetchBuildings: "Building Report",
            allMachines: "Full Machine Report"
        },
        generatedReportOptions: null,
        machineNameString: "",
    }

    const parsedDataInitState = {
        groupOverview: {},
        machines: []
    }

    const [generateFormValues, setGenerateFormValues] = useState(initialState)
    const [parsedData, setParsedData] = useState(parsedDataInitState)    //This useState stores the data 
    const [report, setReport] = useState(reportValuesInitState)
    const routeIP = GetRoute(window.location.hostname)
    const protocol = window.location.protocol

    const fetchReport = async () => {
        try {
            await client.updateToken(5)
            await fetch(`${protocol}//${routeIP}:8000/utils/fetchReport`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    reportType: generateFormValues.reportType,
                    itemsToGet: generateFormValues.itemsToGet,
                    excludeWeekend: generateFormValues.excludeWeekend,
                    startDate: generateFormValues.startDate['$d'],
                    endDate: generateFormValues.endDate['$d'],
                    token: client.token
                })
            }).then ( response => {
                if (!response.ok) {
                    throw new Error("HTTP status code " + response.status)
                }
                return response.json()
            }).then ( data => {
                setParsedData(parseReportData(data, generateFormValues))
            })
        } catch (error) {
            alert(error)
        }
    }

    const generatePDF = async () => {
        const element = reportRef.current

        // Capture the report as a canvas using html2canvas
        const canvas = await html2canvas(element, { scale: 2 })
        const imgData = canvas.toDataURL('image/png')

        // Create a new instance of jsPDF
        const pdf = new jsPDF('portrait', 'pt', 'a4')

        const imgWidth = 595.28 // A4 width in pt
        const pageHeight = 841.89 // A4 height in pt
        const imgHeight = (canvas.height * imgWidth) / canvas.width
        let heightLeft = imgHeight
        let position = 0

        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
        heightLeft -= pageHeight

        // Add more pages if needed
        while (heightLeft > 0) {
            position = heightLeft - imgHeight
            pdf.addPage()
            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
            heightLeft -= pageHeight
        }

        pdf.save('report.pdf')
    }

    const handleSubmit = async () => {
        setReport(reportValuesInitState)
        if (generateFormValues.reportType !== "") {
            setGenerateFormValues(prev => ({...prev, reportErrorValue: false}))
        } 
        if (generateFormValues.reportType === "") {
            alert("Scope field is empty! This field is required.");
            setGenerateFormValues(prev => ({...prev, reportErrorValue: true}))
            return;

        }
        if (generateFormValues.reportType !== `allMachines` && generateFormValues.itemsToGet.length === 0) {
            alert("You must select a report option unless selecting All Machines for your scope.")
            setGenerateFormValues(prev => ({...prev, itemsToGetErrorValue: true}))
            return;
        }
        
        setGenerateFormValues(prev => ({...prev, linearProgressToggle: true})) // Show the progress bar
        await fetchReport() // Wait for fetch to complete
        setGenerateFormValues(prev => ({...prev, reportDialogToggle: true})) // Hide the progress bar
        setGenerateFormValues(prev => ({...prev, linearProgressToggle: false}))
    }

    const handleReset = () => {
        setGenerateFormValues(initialState)
        setParsedData(parsedDataInitState)
        setReport(reportValuesInitState)
    }

    const closeReportDialog = () => {
        setGenerateFormValues(prev => ({...prev, reportDialogToggle: false}))
    }

    const handleChange = (event) => {
        const name = event.target.name
        const value = event.target.value

        setParsedData(parsedDataInitState)
        if (name === 'reportType') {
            setGenerateFormValues(prev => ({...prev, itemsToGet: []}))
        }
        setGenerateFormValues(prev => ({...prev, [name]: value}))
    }

    const generateItems = async () => {
        if (generateFormValues.reportType !== "" && generateFormValues.reportType !== "allMachines") {
            try {
                await client.updateToken(5)
                const headers = { auth: client.token }
                await fetch(`${protocol}//${routeIP}:8000/utils/${generateFormValues.reportType}`, { headers }
                    ).then (response => { 
                        if (!response.ok) {
                            throw new Error("HTTP status code " + response.status)
                        }
                        return response.json()
                    }).then (data => {setGenerateFormValues(prev => ({...prev, reportFetchResult: data}))})
            } catch (error) {
                alert(error)
            }
        }
    }
    
    useEffect(() => {
        generateItems()
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [generateFormValues.reportType])


    useEffect(() => {
        if (parsedData.machines.length > 0) {
            const genReportOptions = generateReportDataOptions(parsedData, generateFormValues)
            const machineNames = getMachineList(parsedData.machines)
            setReport(prev => ({...prev, generatedReportOptions: genReportOptions, machineNameString: machineNames}))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parsedData])

    return (
        <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', marginInline: 'auto', maxWidth: '1920px', minHeight: 'calc(100vh - 329px)' }}>
            <Paper elevation={8} sx={{ marginTop: 5 }}>
                {generateFormValues.linearProgressToggle === true ? (
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box>
                ) : (<Box sx={{ width: '100%', minHeight: '4px' }}></Box>)}
                <Grid container sx={{ justifyContent: 'space-evenly', paddingTop: 2, paddingBottom: 5 }}>
                    <Grid item xs={12} sx={{ margin: 1 }}>
                        <Typography gutterBottom variant="h4" sx={{ textAlign: 'center' }}>
                            Report Generation
                        </Typography>
                    </Grid>
                    { generateFormValues.reportType === `allMachines` ? (
                        <Grid item xs={12} sm={3} sx={{ textAlign: 'center', paddingBottom: 5 }} >
                            <Grid container 
                                direction="column" 
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Grid item xs={12}>
                                    <Typography gutterBottom variant='h6' textAlign='center'>Report Options</Typography>
                                    <FormControl sx={{ width: '250px', margin: 1 }}>
                                        <InputLabel>Scope</InputLabel>
                                        <Select
                                            value={generateFormValues.reportType}
                                            labelId="Scope"
                                            id="Scope"
                                            label="Scope"
                                            name="reportType"
                                            onChange={handleChange}
                                            required
                                            error={ generateFormValues.reportErrorValue === true && generateFormValues.reportType === [] }
                                        >
                                            <MenuItem value={`fetchDepartments`}>Department</MenuItem>
                                            <MenuItem value={`fetchMachines`}>Machine</MenuItem>
                                            <MenuItem value={`fetchBuildings`}>Building</MenuItem>
                                            <MenuItem value={`allMachines`}>All Machines</MenuItem>
                                        </Select>
                                        <FormHelperText>Required</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl sx={{ width: '250px', margin: 1 }}>
                                        <InputLabel>Specific item(s)</InputLabel>
                                        <Select
                                            sx={{ height: '56px' }}
                                            value={generateFormValues.itemsToGet}
                                            labelId="items"
                                            id="items"
                                            label="Specific item(s)"
                                            multiple
                                            name="itemsToGet"
                                            MenuProps={MenuProps}
                                            onChange={handleChange}
                                            // renderValue={(selected) => selected.join(', ')}
                                            error={ generateFormValues.itemsToGetErrorValue === true }
                                            disabled
                                        >
                                            { generateFormValues.reportFetchResult.map( item => {
                                                return (
                                                    <MenuItem key={item} value={item}>
                                                        <Checkbox checked={generateFormValues.itemsToGet.indexOf(item) > -1}/>
                                                        {item}
                                                    </MenuItem>
                                                )
                                            })}
                                        </Select>
                                        <FormHelperText>Required (Unless All Machines is selected)</FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid item xs={12} sm={3} sx={{ textAlign: 'center' }}>
                            <Grid container 
                                direction="column" 
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Grid item xs={12} sm={4}>
                                    <Typography gutterBottom variant='h6' textAlign='center'>Report Options</Typography>
                                    <FormControl sx={{ width: '250px', margin: 1 }}>
                                        <InputLabel>Scope</InputLabel>
                                        <Select
                                            value={generateFormValues.reportType}
                                            labelId="Scope"
                                            id="Scope"
                                            label="Scope"
                                            name="reportType"
                                            onChange={handleChange}
                                            required
                                            error={ generateFormValues.reportErrorValue === true && generateFormValues.reportType === "" }
                                        >
                                            <MenuItem value={`fetchDepartments`}>Department</MenuItem>
                                            <MenuItem value={`fetchMachines`}>Machine</MenuItem>
                                            <MenuItem value={`fetchBuildings`}>Building</MenuItem>
                                            <MenuItem value={`allMachines`}>All Machines</MenuItem>
                                        </Select>
                                        <FormHelperText>Required</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <FormControl sx={{ width: '250px', margin: 1 }}>
                                        <InputLabel>Specific item(s)</InputLabel>
                                        <Select
                                            sx={{ height: '56px' }}
                                            value={generateFormValues.itemsToGet}
                                            labelId="items"
                                            id="items"
                                            label="Specific item(s)"
                                            multiple
                                            required
                                            name="itemsToGet"
                                            MenuProps={MenuProps}
                                            onChange={handleChange}
                                            // renderValue={(selected) => selected.join(', ')}
                                            error={ generateFormValues.itemsToGetErrorValue === true }
                                        >
                                            { generateFormValues.reportFetchResult.map( item => {
                                                return (
                                                    <MenuItem key={item} value={item}>
                                                        <Checkbox checked={generateFormValues.itemsToGet.indexOf(item) > -1}/>
                                                        {item}
                                                    </MenuItem>
                                                )
                                            })}
                                        </Select>
                                        <FormHelperText sx={{ textAlign: 'center' }}>Required (Unless "All Machines" is selected)</FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    <Divider orientation="vertical" flexItem sx={{ mr: '-1px' }} />
                    <Grid item xs={12} sm={3} sx={{ textAlign: 'center' }}>
                        <Typography gutterBottom variant='h6'>Data Tables</Typography>
                        <FormControl component="fieldset" variant="standard" sx={{justifyContent: 'center'}}>
                            <FormGroup>
                                <FormControlLabel
                                    sx={{width: 291.08}}
                                    control={
                                        <Checkbox
                                            checked={generateFormValues.dailyRecordHLATable}
                                            onChange={(e) => {
                                                if (parsedData !== parsedDataInitState) {
                                                    setReport(reportValuesInitState)
                                                }
                                                return setGenerateFormValues(prev => ({...prev, dailyRecordHLATable: !generateFormValues.dailyRecordHLATable}))
                                            }}
                                            name="dailyRecordHLATable" 
                                        />
                                    }
                                    label="Daily Record HLA Table"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            checked={generateFormValues.dailyRecordsToggle}
                                            onChange={(e) => {
                                                if (parsedData !== parsedDataInitState) {
                                                    setReport(reportValuesInitState)
                                                }
                                                return setGenerateFormValues(prev => ({...prev, dailyRecordsToggle: !generateFormValues.dailyRecordsToggle}))
                                            }}
                                            name="dailyRecordsToggle" 
                                        />
                                    }
                                    label="Daily Records Table"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={generateFormValues.errorTableToggle}
                                            onChange={(e) => {
                                                if (parsedData !== parsedDataInitState) {
                                                    setReport(reportValuesInitState)
                                                }
                                                return setGenerateFormValues(prev => ({...prev, errorTableToggle: !generateFormValues.errorTableToggle}))
                                            }}
                                            name="errorTableToggle" 
                                        />
                                    }
                                    label="Error Table"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={generateFormValues.programTableToggle}
                                            onChange={(e) => {
                                                if (parsedData !== parsedDataInitState) {
                                                    setReport(reportValuesInitState)
                                                }
                                                return setGenerateFormValues(prev => ({...prev, programTableToggle: !generateFormValues.programTableToggle}))
                                            }}
                                            name="programTableToggle" 
                                        />
                                    }
                                    label="Program Table"
                                />
                            </FormGroup>
                        </FormControl>
                    </Grid>
                    <Divider orientation="vertical" flexItem sx={{ mr: "-1px" }} />
                    <Grid item xs={12} sm={3} sx={{ textAlign: 'center' }}>
                        <Typography gutterBottom variant='h6'>Data Graphs</Typography>
                        <FormControl component="fieldset" variant="standard">
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={generateFormValues.AverageOperatingTimePieChart}
                                            onChange={(e) => {
                                                if (parsedData !== parsedDataInitState) {
                                                    setReport(reportValuesInitState)
                                                }
                                                return setGenerateFormValues(prev => ({...prev, AverageOperatingTimePieChart: !generateFormValues.AverageOperatingTimePieChart}))
                                            }}
                                            name="AverageOperatingTimePieChart" 
                                        />
                                    }
                                    label="Average Operating Time Pie Chart"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={generateFormValues.dailyRecordsBarGraph}
                                            onChange={(e) => {
                                                if (parsedData !== parsedDataInitState) {
                                                    setReport(reportValuesInitState)
                                                }
                                                return setGenerateFormValues(prev => ({...prev, dailyRecordsBarGraph: !generateFormValues.dailyRecordsBarGraph}))
                                            }}
                                            name="dailyRecordBarGraph" 
                                        />
                                    }
                                    label="Daily Records Bar Graph"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={generateFormValues.dailyRecordHLABarGraph}
                                            onChange={(e) => {
                                                if (parsedData !== parsedDataInitState) {
                                                    setReport(reportValuesInitState)
                                                }
                                                return setGenerateFormValues(prev => ({...prev, dailyRecordHLABarGraph: !generateFormValues.dailyRecordHLABarGraph}))
                                            }}
                                            name="dailyRecordHLABarGraph" 
                                        />
                                    }
                                    label="Daily Record HLA Bar Graph"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={generateFormValues.groupOverview}
                                            onChange={(e) => {
                                                if (parsedData !== parsedDataInitState) {
                                                    setReport(reportValuesInitState)
                                                }
                                                return setGenerateFormValues(prev => ({...prev, groupOverview: !generateFormValues.groupOverview}))
                                            }}
                                            name="Overview"
                                        />
                                    }
                                    label="Overview"
                                />
                            </FormGroup>
                            <Typography gutterBottom variant='h6'>Filters</Typography>
                                <FormControl component="fieldset" variant="standard">
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={generateFormValues.excludeWeekend}
                                                    onChange={(e) => {
                                                        if (parsedData !== parsedDataInitState) {
                                                            setReport(reportValuesInitState)
                                                        }
                                                        return setGenerateFormValues(prev => ({...prev, excludeWeekend: !generateFormValues.excludeWeekend}))
                                                    }}
                                                    name="excludeWeekend" 
                                                />
                                            }
                                            label="Exclude Weekends"
                                        />
                                    </FormGroup>
                                </FormControl>
                            {/* <FormHelperText sx={{ textAlign: 'center' }}>These options represent data that will be displayed</FormHelperText>
                            <FormHelperText sx={{ textAlign: 'center' }}>after submitting the report generation form.</FormHelperText> */}
                        </FormControl>
                    </Grid>
                    <Divider orientation="vertical" flexItem sx={{ mr: "-1px" }} />
                    <Grid item xs={12} sm={3} sx={{ textAlign: 'center' }}>
                        <Grid container 
                            direction="column" 
                            justifyContent="center"
                            alignItems="center"
                        >
                            {/* <Grid item xs={12} sx={{ paddingBottom: 5 }}>
                                <Typography gutterBottom variant='h6'>Filters</Typography>
                                <FormControl component="fieldset" variant="standard">
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={generateFormValues.excludeWeekend}
                                                    onChange={(e) => {
                                                        if (parsedData !== parsedDataInitState) {
                                                            setReport(reportValuesInitState)
                                                        }
                                                        return setGenerateFormValues(prev => ({...prev, excludeWeekend: !generateFormValues.excludeWeekend}))
                                                    }}
                                                    name="excludeWeekend" 
                                                />
                                            }
                                            label="Exclude Weekends"
                                        />
                                    </FormGroup>
                                </FormControl>
                            </Grid> */}
                            <Grid item xs={12}>
                                <Typography gutterBottom textAlign='center' variant='h6' sx={{ paddingBottom: 1 }}>Date Options</Typography>
                                <DatePicker 
                                    label="Start Date" 
                                    timezone="America/Chicago"
                                    value={generateFormValues.startDate}
                                    onChange={(e) => {
                                        if (parsedData !== parsedDataInitState) {
                                            setReport(reportValuesInitState)
                                        }
                                        return setGenerateFormValues(prev => ({...prev, startDate: e}))
                                    }}
                                    maxDate={dayjs()}
                                />
                                <FormHelperText sx={{ paddingLeft: 2, paddingBottom: 3 }}>Start date is date selected at 12:00AM</FormHelperText>
                            </Grid>
                            <Grid item xs={12}>
                                <DatePicker 
                                    label="End Date" 
                                    timezone="America/Chicago"
                                    value={generateFormValues.endDate}
                                    onChange={(e) => {
                                        if (parsedData !== parsedDataInitState) {
                                            setReport(reportValuesInitState)
                                        }
                                        return setGenerateFormValues(prev => ({...prev, endDate: e}))
                                    }}
                                    maxDate={dayjs()}
                                />
                                <FormHelperText sx={{ paddingLeft: 2, paddingBottom: 2 }}>End date is date selected at 11:59PM</FormHelperText>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container sx={{ justifyContent: 'flex-end' }}>
                    <Grid item sx={{ paddingBottom: 1, paddingTop: 1 }}>
                        <Button
                            variant="contained" 
                            id="submitButton"
                            size='large'
                            sx={{
                                backgroundColor: '#0e4780',
                                width: 120,
                                color: 'white',
                                textTransform: 'none',
                                "&:hover": { backgroundColor: '#0B3866', color: "white" }
                            }}
                            onClick={() => {
                                handleSubmit()
                                const button = document.querySelector('#submitButton')
                                button.disabled = true
                                setTimeout(() => button.disabled = false, 2000)
                            }}
                        >
                            Submit
                        </Button>
                    </Grid>
                    <Grid item sx={{ margin: 1 }}>
                        <Button
                            variant="contained"
                            id="resetButton"
                            size='large'
                            sx={{
                                backgroundColor: '#5d6c7c',
                                width: 120,
                                color: 'white',
                                textTransform: 'none',
                                "&:hover": { bgcolor: '#4f5d6a', color: "white" }
                            }}
                            onClick={() => {
                                handleReset()
                                const button = document.querySelector('#resetButton')
                                button.disabled = true
                                setTimeout(() => button.disabled = false, 2000)
                            }}
                        >
                            Reset
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
            <Dialog
                open={generateFormValues.reportDialogToggle}
                onClose={closeReportDialog}
                fullWidth
                maxWidth="lg"
                sx={{ color: '#0e4780' }}
            >
                <DialogTitle sx={{ backgroundColor: '#0e4780', color: 'white' }}>
                    R&S Machine Report
                    <IconButton
                        aria-label="close"
                        onClick={closeReportDialog}
                        sx={{ color: 'white', position: 'absolute', right: 8, top: 8 }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <div ref={reportRef}>
                        <GenerateReport
                            scopeObject={report.ScopeObject}
                            formValues={generateFormValues}
                            reportOptions={report.generatedReportOptions}
                            machineNames={report.machineNameString}
                        />
                    </div>
                </DialogContent>
                <DialogActions sx={{ backgroundColor: '#5d6c7c' }}>
                    <Button
                        size='large'
                        id="pdfButton"
                        sx={{
                            backgroundColor: '#0e4780',
                            textTransform: 'none',
                            color: 'white',
                            padding: 1.5,
                            "&:hover": { backgroundColor: '#0B3866', color: "white" }
                        }}
                        onClick={() => {
                                const button = document.querySelector('#pdfButton')
                                button.disabled = true
                                setTimeout(() => button.disabled = false, 5000)
                                generatePDF()
                            }
                        }
                    >
                        Download PDF
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export default ReportGeneration
