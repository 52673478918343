import { React, useState, useEffect, useRef } from 'react'
import { Grid, Box, Paper, TextField, Link, DialogTitle, DialogContent, Stack, FormControlLabel } from "@mui/material/" 
import { FormControl, FormLabel, RadioGroup, Radio, Autocomplete } from "@mui/material/"
import LoadingButton from '@mui/lab/LoadingButton'
import MuiPhoneNumber from 'mui-phone-number';
import validator from 'validator'
import VisitorFODDialog from './PDFHandling/VisitorFOD'
import VisitorNDADialog from './PDFHandling/VisitorNDA'
import SubmissionResult from './SubmissionResult'
import RegistrationErrorDialog from './RegErrorDialog'
import { Popper } from '@mui/material'
import { styled } from '@mui/material/styles'
import InactiveRedirect from '../InactiveRedirect'
import IdleUserDialog from '../IdleUser'
import GetRoute from '../../GetRoute'

export default function CreateVisitorAccount ({ client }) {
    const routeIP = GetRoute(window.location.hostname)
    const protocol = window.location.protocol
    
    InactiveRedirect(() => setRegistrationFormValues((prev) => ({...prev, handleIdleUserToggle:  true})))
    const registrationFormInitState = {
        firstName: "",
        firstNameErrorValue: false,
        lastName: "",
        lastNameErrorValue: false,
        email: "",
        emailErrorValue: false,
        company: null,
        availableCompanies: [],
        companyErrorValue: false,
        phoneNumber: "",
        phoneNumberErrorValue: false,
        ndaDialogToggle: false,
        ndaOpened: 0,
        ndaOpenedErrorValue: false,
        ndaAcceptance: 0,
        ndaAcceptanceErrorValue: false,
        fodDialogToggle: false,
        fodOpened: 0,
        fodOpenedErrorValue: false,
        fodAcceptance: 0,
        fodAcceptanceErrorValue: false,
        usCitizen: 0,
        submitting: false,
        registrationErrorDialogToggle: false,
        registrationErrorArray: [],
        handleIdleUserToggle: false
    }

    const firstNameRef = useRef(null)
    const lastNameRef = useRef(null)
    const companyRef = useRef(null)

    const LightGreyPopper = styled(Popper)({
        '& .MuiAutocomplete-paper': {
          backgroundColor: '#E5E4E2', // Light grey color
        },
    })

    const [registrationFormValues, setRegistrationFormValues] = useState(registrationFormInitState)
    const [submissionResult, setSubmissionResult] = useState({
        submissionRecieved: false,
        submissionDialogToggle: false,
        message: null, 
        result: null
    })

    const openFodDialog = () => {
        setRegistrationFormValues((prev) => ({...prev, fodDialogToggle: true}))
    }

    const openNdaDialog = () => {
        setRegistrationFormValues((prev) => ({...prev, ndaDialogToggle: true}))
    }

    const handleAutoScroll = (reference) => {
        reference.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }

    useEffect(() => {
        fetchCompanies()
        // eslint-disable-next-line
    }, [])

    const fetchCompanies = async () => {
        try {
            await client.updateToken(5)
            const headers = { auth: client.token }
            const response = await fetch(`${protocol}//${routeIP}:8000/utils/visitorRegistrationCompanies`,{ headers })

            if (!response.ok) {
                const error = await response.json()
                throw new Error(error.message)
            }

            const data = await response.json()
            data.push("None", "Not Listed")
            setRegistrationFormValues((prev) =>({...prev, availableCompanies: data}))

        } catch (error) {
            alert(error)
        }
    }

    const submitRegistrationForm = async () => {
        try {
            await client.updateToken(5)
            const response = await fetch(`${protocol}//${routeIP}:8000/utils/submitUserRegistration`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    firstName: registrationFormValues.firstName,
                    lastName: registrationFormValues.lastName,
                    email: registrationFormValues.email,
                    company: registrationFormValues.company,
                    phoneNumber: registrationFormValues.phoneNumber,
                    ndaOpened: registrationFormValues.ndaOpened,
                    ndaAcceptance: registrationFormValues.ndaAcceptance,
                    fodOpened: registrationFormValues.fodOpened,
                    fodAcceptance: registrationFormValues.fodAcceptance,
                    usCitizen: registrationFormValues.usCitizen,
                    token: client.token
                })
            })

            if (!response.ok) {
                const error = await response.json()
                throw new Error(error.message)
            }

            const data = await response.json()
            if (!data['result']) {
                setSubmissionResult((prev) => ({...prev, submissionRecieved: true, result: data['result'], message: "Registration failed. Email has already been used."}))
            } else {
                setSubmissionResult((prev) => ({...prev, submissionRecieved: true, result: data['result'], message: "Registration success. Would you like to sign in?"}))
            }

        } catch (error) {
            alert(error)
            setSubmissionResult((prev) => ({...prev, submissionRecieved: true, message: error, result: false}))
        }
    }

    const handleChange = (event) => {
        const name = event.target.name
        const value = event.target.value

        setRegistrationFormValues(prev => ({...prev, [name]: value}))
    }

    const checkRegistrationFields = () => {
        var hasError = false
        var alertArray = []

        if (registrationFormValues.firstName === "") {
            alertArray.push("Enter your first name")
            setRegistrationFormValues(prev => ({...prev, firstNameErrorValue: true}))
            hasError = true
        }

        if (registrationFormValues.lastName === "") {
            alertArray.push("Enter your last name")
            setRegistrationFormValues(prev => ({...prev, lastNameErrorValue: true}))
            hasError = true
        }

        if (registrationFormValues.company === null) {
            alertArray.push("Select a value from the company drop down options")
            setRegistrationFormValues(prev => ({...prev, companyErrorValue: true}))
            hasError = true
        } 

        if (!validator.isEmail(registrationFormValues.email)) {
            alertArray.push("Enter your email")
            setRegistrationFormValues(prev => ({...prev, emailErrorValue: true}))
            hasError = true
        }
        
        if (["", "+", "+1"].includes(registrationFormValues.phoneNumber)) {
            alertArray.push("Enter your phone number")
            setRegistrationFormValues(prev => ({...prev, phoneNumberErrorValue: true}))
            hasError = true
        }

        if (!registrationFormValues.ndaAcceptance || !registrationFormValues.ndaOpened) {
            alertArray.push("Open, read, and accept the NDA document")
            if (!registrationFormValues.ndaAcceptance) {
                setRegistrationFormValues(prev => ({...prev, ndaAcceptanceErrorValue: true}))
            } 
            if (!registrationFormValues.ndaOpened) {
                setRegistrationFormValues(prev => ({...prev, ndaOpenedErrorValue: true}))
            }
            hasError = true
        }

        if (!registrationFormValues.fodAcceptance || !registrationFormValues.fodOpened) {
            alertArray.push("Open, read, and accept the FOD document")
            if (!registrationFormValues.fodAcceptance) {
                setRegistrationFormValues(prev => ({...prev, fodAcceptanceErrorValue: true}))
            }
            if (!registrationFormValues.fodOpened) {
                setRegistrationFormValues(prev => ({...prev, fodOpenedErrorValue: true}))
            }
            hasError = true
        }

        if (hasError) {
            setRegistrationFormValues(prev => ({...prev, registrationErrorArray: alertArray}))
            setRegistrationFormValues(prev => ({...prev, registrationErrorDialogToggle: true}))
        }

        return !hasError
    }


    return (
        <Box sx={{ backgroundColor: '#002233' }}>
            <Grid container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginInline: 'auto', maxWidth: '1920px', minHeight: 'calc(100vh - 63.59px)' }}>
                <Grid item xs={0} sm={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Box className='apolloLogoBackground' sx={{  alignSelf: 'center' }}/>
                </Grid>
                <Grid item xs={12} sm={12} md={6} sx={{ display: 'flex', alignItems: 'baseline', justifyContent: 'center', color: 'white', padding: '2%' }} >
                    <Paper>
                        <DialogTitle sx={{ textAlign: 'left', backgroundColor: '#0e4780', color: 'white' }}>Create account</DialogTitle>
                        <DialogContent dividers sx={{ padding: 4 }}>
                            <Stack spacing={2}>
                                <TextField
                                    name="firstName"
                                    variant="outlined" 
                                    label="First name"
                                    autoComplete="new-password"
                                    inputRef={firstNameRef}
                                    value={registrationFormValues.firstName}
                                    error={registrationFormValues.firstNameErrorValue && registrationFormValues.firstName === ""}
                                    onChange={handleChange}
                                />
                                <TextField
                                    name="lastName"
                                    variant="outlined" 
                                    label="Last name"
                                    autoComplete="new-password"
                                    inputRef={lastNameRef}
                                    value={registrationFormValues.lastName}
                                    error={registrationFormValues.lastNameErrorValue && registrationFormValues.lastName === ""}
                                    onClick={() => {
                                        if (window.innerHeight < 900) {
                                            handleAutoScroll(firstNameRef)
                                        }
                                    }}
                                    onChange={handleChange}
                                />
                                <Autocomplete
                                    disablePortal
                                    id="company"
                                    value={registrationFormValues.company}
                                    options={registrationFormValues.availableCompanies}
                                    PopperComponent={LightGreyPopper}
                                    isOptionEqualToValue={(option, value) => option === value}
                                    onChange={(event, newValue) => {
                                        setRegistrationFormValues((prev) => ({...prev, company: newValue}))
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            name="company"
                                            variant="outlined"
                                            label="Company"
                                            inputRef={companyRef}
                                            onClick={() => {
                                                if (window.innerHeight < 900) {
                                                    handleAutoScroll(lastNameRef)
                                                }
                                            }}
                                            error={registrationFormValues.companyErrorValue && !registrationFormValues.availableCompanies.includes(registrationFormValues.company)}
                                            helperText={
                                                <>
                                                    <span style={{ margin: 0, fontSize: 16 }}>If company not listed, select 'Not Listed'</span>
                                                    <br />
                                                    <span style={{ margin: 0, fontSize: 16 }}>If not representing a company, select 'None'</span>
                                                </>
                                            }
                                        />
                                    )}
                                />
                                <TextField
                                    name="email"
                                    variant="outlined" 
                                    label="Email"
                                    autoComplete="new-password"
                                    value={registrationFormValues.email}
                                    onClick={() => {
                                        if (window.innerHeight < 900) {
                                            handleAutoScroll(companyRef)
                                        }
                                    }}  
                                    onChange={handleChange}
                                    style={{ 'marginTop': '8px' }}
                                    error={ !(validator.isEmail(registrationFormValues.email)) && registrationFormValues.emailErrorValue === true}
                                >
                                </TextField>
                                <MuiPhoneNumber 
                                    defaultCountry={'us'}
                                    onlyCountries={['us']}
                                    name="phoneNumber"
                                    variant="outlined" 
                                    label="Phone number"
                                    autoComplete="new-password"
                                    value={registrationFormValues.phoneNumber}
                                    error={registrationFormValues.phoneNumberErrorValue && ["", "+", "+1"].includes(registrationFormValues.phoneNumber)}
                                    onChange={(value) => {setRegistrationFormValues(prev => ({...prev, phoneNumber: value}))}}
                                />
                            </Stack>
                            <Stack
                                direction="row"
                                spacing={2}
                                marginTop={4}
                                marginBottom={4}
                                sx={{ display: 'flex', justifyContent: 'space-evenly' }}
                            >
                                <Stack spacing={2}>
                                    <FormControl>
                                        <FormLabel>Agree to{' '}
                                            <Link
                                                sx={{ 
                                                    color: registrationFormValues.ndaOpenedErrorValue || (registrationFormValues.ndaOpened && !registrationFormValues.ndaAcceptance) ? 'red': '',
                                                    textDecorationColor: registrationFormValues.ndaOpenedErrorValue || (registrationFormValues.ndaOpened && !registrationFormValues.ndaAcceptance) ? 'red': ''
                                                }}
                                                href="#" 
                                                onClick={(event) => {
                                                    event.preventDefault()
                                                    setRegistrationFormValues(prev => ({...prev, ndaOpened: 1}))
                                                    setRegistrationFormValues(prev => ({...prev, ndaOpenedErrorValue: false}))
                                                    openNdaDialog()
                                                }}
                                            > 
                                                NDA
                                            </Link>
                                        </FormLabel>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Agree to{' '}
                                            <Link
                                                sx={{ 
                                                    color: registrationFormValues.fodOpenedErrorValue || (registrationFormValues.fodOpened && !registrationFormValues.fodAcceptance) ? 'red': '',
                                                    textDecorationColor: registrationFormValues.fodOpenedErrorValue || (registrationFormValues.fodOpened && !registrationFormValues.fodAcceptance) ? 'red': ''
                                                }}
                                                href="#" 
                                                onClick={(event) => {
                                                    event.preventDefault()
                                                    setRegistrationFormValues(prev => ({...prev, fodOpened: 1}))
                                                    setRegistrationFormValues(prev => ({...prev, fodOpenedErrorValue: false}))
                                                    openFodDialog()
                                                }}
                                            > 
                                                FOD
                                            </Link>
                                        </FormLabel>
                                    </FormControl>
                                </Stack>
                                <FormControl>
                                    <FormLabel>Are you a U.S. citizen?</FormLabel>
                                    <RadioGroup
                                        value={registrationFormValues.usCitizen}
                                        onChange={(e) => {
                                            setRegistrationFormValues(prev => ({...prev, usCitizen: parseInt(e.target.value)}))
                                        }} 
                                        name="us-citizen-radio-group"
                                        row
                                    >
                                        <FormControlLabel 
                                            value={1} 
                                            control={<Radio />} 
                                            label="Yes" 
                                        />
                                        <FormControlLabel 
                                            value={0} 
                                            control={<Radio />} 
                                            label="No" 
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Stack>
                            <Stack>
                                <LoadingButton
                                    loading={registrationFormValues.submitting}
                                    id="submitButton"
                                    sx={{ 
                                        padding: 1.5, 
                                        fontSize: 16, 
                                        color: 'white', 
                                        backgroundColor: '#0e4780', 
                                        textTransform: 'none', 
                                        '&:hover': { cursor: 'pointer', backgroundColor: '#003c6c' }, '& .MuiCircularProgress-root': { color: 'white' } 
                                    }}
                                    onClick={ async () => {
                                        const button = document.querySelector("#submitButton")
                                        button.disabled = true
                                        setTimeout(() => button.disabled = false, 3000)
                                        setRegistrationFormValues((prev) => ({...prev, submitting: true}))
                                        const isValid = checkRegistrationFields()
                                        if (isValid) {
                                            await new Promise(r => setTimeout(r, 1000))
                                            await submitRegistrationForm()
                                            setSubmissionResult((prev) => ({...prev, submissionDialogToggle: true}))
                                        }
                                        setRegistrationFormValues((prev) => ({...prev, submitting: false}))
                                    }}
                                >
                                    Submit
                                </LoadingButton>
                            </Stack>
                        </DialogContent>
                    </Paper>
                </Grid>
            </Grid>
            { registrationFormValues.ndaDialogToggle ? 
                <VisitorNDADialog 
                    ndaDialogToggle={registrationFormValues.ndaDialogToggle} 
                    setRegistrationFormValues={setRegistrationFormValues}
                /> : <></>
            }
            { registrationFormValues.fodDialogToggle ? 
                <VisitorFODDialog 
                    fodDialogToggle={registrationFormValues.fodDialogToggle} 
                    setRegistrationFormValues={setRegistrationFormValues}
                /> : <></>
            }
            { registrationFormValues.registrationErrorDialogToggle ? 
                <RegistrationErrorDialog 
                    registrationFormValues={registrationFormValues} 
                    setRegistrationFormValues={setRegistrationFormValues}
                />: <></>
            }
            { submissionResult.submissionDialogToggle ? 
                <SubmissionResult 
                    submissionResult={submissionResult} 
                    setSubmissionResult={setSubmissionResult} 
                />: <></>
            }
            { registrationFormValues.handleIdleUserToggle ?
                <IdleUserDialog
                    state={registrationFormValues} 
                    setState={setRegistrationFormValues} 
                /> : <></>
            }
        </Box>
    )
}