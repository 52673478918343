import * as React from 'react'
import { useState } from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { Box, Grid, Paper, Typography, FormControl, Button, TextField } from '@mui/material/'
import LinearProgress from '@mui/material/LinearProgress'
import GetRoute from '../GetRoute'

export default function InventoryCheckForm({ client }) {
    const routeIP = GetRoute(window.location.hostname)
    const protocol = window.location.protocol

    const initInventoryState = {
        linearProgressToggle: false,
        requestedPart: "",
        requestedPartErrorValue: false,
        response: null
    }
    const [inventoryState, setInventoryState] = useState(initInventoryState)

    const fetchReport = async () => {
        setInventoryState(prev => ({...prev, linearProgressToggle: true}))
        try {
            // eslint-disable-next-line
            await client.updateToken(5)
            const response = await fetch(`${protocol}//${routeIP}:8000/utils/fetchPartInventory`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    requestedPart: inventoryState.requestedPart,
                    token: client.token
                })
            })

            if (!response.ok) {
                const error = await response.json()
                throw new Error(error.message)
            }

            const data = await response.json()
            setInventoryState(prev => ({...prev, response: data}))
            setInventoryState(prev => ({...prev, linearProgressToggle: false}))

        } catch (error) {
            alert(error)
            setInventoryState(prev => ({...prev, linearProgressToggle: false}))
        }
    }

    const handleSubmit = async () => {
        if (inventoryState.requestedPartErrorValue === false) {
            await fetchReport()
        } else {
            alert("Invalid Part Number")
        }
    }

    const handleChange = async (event) => {
        const name = event.target.name
        const value = event.target.value
        await setInventoryState(prev => ({...prev, [name]: value}))
    }
    const handleReset = () => {
        setInventoryState(initInventoryState)
    }

    return (
        <Grid container sx={{ height: '100%', display: 'flex', gridTemplateRows: 'auto 1fr' }}>
            <Grid item sm={12} sx={{ height: '30%', minHeight: '171px' }}>
                <Paper elevation={1} sx={{ height: '100%' }}>
                    { inventoryState.linearProgressToggle === true ? (
                            <Box sx={{ width: '100%' }}>
                                <LinearProgress />
                            </Box>
                            ) : (<Box sx={{ width: '100%', minHeight: '4px', backgroundColor: 'white' }}></Box>
                        )
                    }
                    <Grid container sx={{ height: '98%' }}>
                        <Grid item sm={0} md={2}/>
                            <Grid item sm={12} md={8} sx={{ display: 'flex', justifyContent: 'center', height: 'fit-content' }}>
                                <Grid container sx={{ margin: 1, display: 'flex', justifyContent: 'space-evenly' }}>
                                    <Grid item sm={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Paper elevation={4} sx={{ backgroundColor: '#0e4780', width: '100%' }}>
                                            <Typography gutterBottom variant="h6" sx={{ textAlign: 'center', color:'white', margin: 0 }}>Inventory Check</Typography>
                                        </Paper>
                                    </Grid>
                                    <Grid item sm={8} sx={{ paddingTop: 1.5 }}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <TextField
                                                required
                                                autoComplete='off'
                                                id="selectedPart"
                                                label="Part Number"
                                                name="requestedPart"
                                                error={inventoryState.requestedPartErrorValue}
                                                value={inventoryState.requestedPart}
                                                onChange={handleChange}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item sm={2} alignContent='center' sx={{ paddingTop: 1.5, paddingLeft: 1.5 }}>
                                        <Button
                                            size='large'
                                            variant="contained"
                                            style={{ textTransform: 'none' }}
                                            id="submitButton"
                                            sx={{ 
                                                backgroundColor: '#0e4780', 
                                                fontSize: 16, 
                                                width: '-webkit-fill-available', 
                                                color: 'white', 
                                                "&:hover": { backgroundColor: '#0B3866', color: "white" }
                                            }}
                                            onClick={() => {
                                                handleSubmit()
                                                const button = document.querySelector('#submitButton')
                                                button.disabled = true
                                                setTimeout(() => button.disabled = false, 1000)
                                            }}
                                        >
                                            Submit
                                        </Button>
                                    </Grid>
                                    <Grid item sm={2} alignContent='center' sx={{ paddingTop: 1.5, paddingLeft: 1.5 }}>
                                        <Button
                                            size='large'
                                            variant="contained"
                                            style={{ textTransform: 'none' }}
                                            id="resetButton"
                                            sx={{ 
                                                backgroundColor: '#5d6c7c', 
                                                fontSize: 16, 
                                                width: '-webkit-fill-available', 
                                                color: 'white', 
                                                "&:hover": { backgroundColor: '#4f5d6a', color: "white" }
                                            }}
                                            onClick={() => {
                                                handleReset()
                                                const button = document.querySelector('#resetButton')
                                                button.disabled = true
                                                setTimeout(() => button.disabled = false, 1000)
                                            }}
                                        >
                                            Reset
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        <Grid item sm={0} md={2}/>
                    </Grid>
                </Paper>              
            </Grid>
            { inventoryState.response != null ? (
                <Grid item sm={12} sx={{ marginTop: 1, backgroundColor: 'lightgrey', height: '69%' }}>
                    <Paper elevation={1} sx={{ width: '100%', height: '100%', overflow: 'auto' }}>
                        <DataGrid 
                            rows={inventoryState.response.rows} 
                            columns={inventoryState.response.columns.map((column) => ({ 
                                ...column,
                                headerClassName: 'custom-header' // Apply custom-header class to color column headers // Apply the custom-header class to color the headers grey
                            }))} 
                            slots={{ 
                                toolbar: GridToolbar, 
                            }}
                        />
                    </Paper>
                </Grid>
            ):(<></>)}
        </Grid>
        
    )
}