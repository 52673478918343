import { React } from 'react'
import { Typography, Dialog, DialogTitle, DialogContent, Stack, IconButton } from "@mui/material/"
import CloseIcon from '@mui/icons-material/Close'
import LoadingButton from '@mui/lab/LoadingButton'
import GetRoute from '../../GetRoute'


export default function SignOutDialog({ client, loginState, setLoginState, initLoginState }) {
    const routeIP = GetRoute(window.location.hostname)
    const protocol = window.location.protocol
    
    const closeSignOutVisitorDialog = () => {
        setLoginState((prev) => (initLoginState))
    }
    
    const signOutVisitor = async () => {
        try {
            await client.updateToken(5)
            const response = await fetch(`${protocol}//${routeIP}:8000/utils/signOutVisitor`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: loginState.email,
                    token: client.token
                })
            })
            
            if (!response.ok) {
                const error = await response.json()
                throw new Error(error.message)
            }

            const data = await response.json()
            setLoginState((prev) => ({...prev, signoutConfirmationToggle: data['result'], signoutVisitorToggle: false, message: "Have a great day!"}))

        } catch (error) {
            alert(error)
        }
    }

    return (
        <Dialog
            open={loginState.signoutVisitorToggle}
            onClose={closeSignOutVisitorDialog}
            fullWidth
            maxWidth="xs"
        >
            <DialogTitle sx={{ backgroundColor: '#0e4780', color: 'white' }}>
                Confirm sign out
                <IconButton
                    size='small'
                    onClick={closeSignOutVisitorDialog}
                    sx={{ float: 'right', color: 'white' }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers sx={{ padding: 4 }}>
                <Stack spacing={2}>
                    <Typography sx={{ fontSize: 20, paddingBottom: 2 }}>
                        {loginState.visitorName}, are you sure you want to go?
                    </Typography>
                    <LoadingButton
                        loading={loginState.loadingButtonToggle}
                        id="submitButton"
                        sx={{ 
                            padding: 1.5, 
                            fontSize: 16, 
                            color: 'white', 
                            backgroundColor: '#0e4780', 
                            textTransform: 'none', 
                            '&:hover': { cursor: 'pointer', backgroundColor: '#0e4780' }, '& .MuiCircularProgress-root': { color: 'white' } 
                        }}
                        onClick={ async () => {
                            const button = document.querySelector("#submitButton")
                            button.disabled = true
                            setTimeout(() => button.disabled = false, 3000)
                            setLoginState((prev) => ({...prev, loadingButtonToggle: true}))
                            signOutVisitor()
                            setLoginState((prev) => ({...prev, loadingButtonToggle: false}))
                        }}
                    >
                        Yes, Sign Out
                    </LoadingButton>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}