import * as React from 'react'
import { AppBar, Box, Typography, IconButton, SwipeableDrawer, Divider, Grid } from '@mui/material/'
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material/'
import { Link } from 'react-router-dom'
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing'
import ConnectedTvIcon from '@mui/icons-material/ConnectedTv';
import BusinessIcon from '@mui/icons-material/Business'
import AssessmentIcon from '@mui/icons-material/Assessment';
import ArticleIcon from '@mui/icons-material/Article';
import MicrosoftIcon from '@mui/icons-material/Microsoft';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import LogoutIcon from '@mui/icons-material/Logout';
import Logo from '../Logo.png'
import MobileLogo from '../MobileLogo.png'

export default function EmployeeAppbar({ client }) {

    const machinePages = [
        {name: "3-Axis", link: '/Three-Axis-Dashboard'},
        {name: "5-Axis", link: '/Five-Axis-Dashboard'},
        {name: "Horizontal", link: '/Horizontal-Dashboard'},
        {name: "Lathe", link: '/Lathe-Dashboard'},
        {name: "Semiconductor", link: '/Semiconductor-Dashboard'},
        {name: "All Departments", link: '/Machine-Dashboard'}
    ]

    const buildings = [
    {name: "Building 2", link: '/Building-Two-Dashboard'},
    {name: "Building 3", link: '/Building-Three-Dashboard'}
    ]

    const serverWall = [
    {name: "Server wall legend", link: '/serverWallLegend'},
    {name: "Server wall TV 1", link: '/pageOne'},
    {name: "Server wall TV 2", link: '/pageTwo'},
    {name: "Server wall TV 3", link: '/pageThree'},
    {name: "Server wall TV 4", link: '/pageFour'},
    {name: "Server wall TV 5", link: '/pageFive'},
    {name: "Server wall TV 6", link: '/pageSix'},
    {name: "Server wall TV 7", link: '/pageSeven'},
    {name: "Server wall TV 8", link: '/pageEight'},
    {name: "Server wall TV 9", link: '/pageNine'},
    {name: "Server wall TV 10", link: '/pageTen'},
    {name: "Server wall TV 11", link: '/pageEleven'},
    {name: "Server wall TV 12", link: '/pageTwelve'},
    {name: "Server wall TV 13", link: '/pageThirteen'},
    {name: "Server wall TV 14", link: '/pageFourteen'},
    {name: "Server wall TV 15", link: '/pageFifteen'},
    {name: "Server wall TV 16", link: '/pageSixteen'},
    {name: "Server wall TV 17", link: '/pageSeventeen'},
    {name: "Server wall TV 18", link: '/pageEighteen'}
    ]

    const utils = [
    {name: 'Portal', link: '/portal', icon: <RocketLaunchIcon />},
    {name: 'FAI Conversion', link:'/faiConverter', icon: <ArticleIcon />},
    {name: 'Machine Reports', link:'/reportGeneration', icon: <AssessmentIcon />}
    ]


    const [machineMonitoringButton, setMachineMonitoringButton] = React.useState({ left: false })
    const [utilsButton, setUtilsButton] = React.useState({ left: false })

    const toggleMachineMonitoringDrawer = (anchor, open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) return
        setMachineMonitoringButton({ ...machineMonitoringButton, [anchor]: open })
    }

    const toggleUtilsDrawer = (anchor, open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) return
        setUtilsButton({ ...utilsButton, [anchor]: open})
    }

    const utilsList = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 300 }}
            role="presentation"
            onClick={toggleUtilsDrawer(anchor, false)}
            onKeyDown={toggleUtilsDrawer(anchor, false)}
        >
        <Grid container>
            <Grid item xs={12}>
            <Typography align='center' sx={{ margin: 1 }} variant="h5">Utilities</Typography>
            <Divider/>
            <List>
                {utils.map(page => (
                <ListItem key={page.name} disablePadding>
                    <ListItemButton
                        component={Link}
                        to={page.link}
                    >
                    <ListItemIcon>
                        {page.icon}
                    </ListItemIcon>
                    <ListItemText primary={page.name} />
                    </ListItemButton>
                </ListItem>
                ))}
                <ListItem key={'FirstPartInspectionForm'} disablePadding>
                    <ListItemButton
                        onClick = {() => window.open('https://forms.office.com/r/nPLM5npdAn')}
                    >
                    <ListItemIcon>
                        <MicrosoftIcon />
                    </ListItemIcon>
                    <ListItemText primary={'First Part Inspection Form'} />
                    </ListItemButton>
                </ListItem>
                <ListItem key={'FirstPartInspectionExcelDoc'} disablePadding>
                    <ListItemButton
                        onClick = {() => window.open('https://rsmachining.sharepoint.com/:x:/s/QC/EaRC8bstGh1CtFe5jrYOo1IBAtHmdE9TNzkPH1ZkZY-Eyw?e=yo31bO')}
                    >
                    <ListItemIcon>
                        <MicrosoftIcon />
                    </ListItemIcon>
                    <ListItemText primary={'First Part Inspection Excel Doc'} />
                    </ListItemButton>
                </ListItem>
                <ListItem key={'MaintenanceLog'} disablePadding>
                    <ListItemButton
                        onClick = {() => window.open('https://forms.office.com/Pages/ResponsePage.aspx?id=2AtJBmYLZ0-5teQdnequkck41_FwtYRDjBApFmD25IdUODlKRFEzNE1RVzMzNzgzTElTVjlUSFVMMyQlQCN0PWcu')}
                    >
                    <ListItemIcon>
                        <MicrosoftIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Maintenance Log'} />
                    </ListItemButton>
                </ListItem>
                <ListItem key={'CommunicationForm'} disablePadding>
                    <ListItemButton
                        onClick = {() => window.open('https://forms.office.com/Pages/ResponsePage.aspx?id=2AtJBmYLZ0-5teQdnequkaByxeeX88hGsjFC8TnQjG5UN1gyUUo3RUo2M0xUMTVRSktaWFMwQktRSyQlQCN0PWcu')}
                    >
                    <ListItemIcon>
                        <MicrosoftIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Programming Communication Form'} />
                    </ListItemButton>
                </ListItem>
            </List>
            </Grid>
        </Grid>
        </Box>
    )

    const machineMonitoringList = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 500 }}
            role="presentation"
            onClick={toggleMachineMonitoringDrawer(anchor, false)}
            onKeyDown={toggleMachineMonitoringDrawer(anchor, false)}
        >
            <Grid container>
                <Grid item xs={6}>
                    <Typography align='center' sx={{ margin: 1 }} variant="h5">Server Wall</Typography>
                    <Divider/>
                    <List>
                        {serverWall.map(page => (
                            <ListItem key={page.name} disablePadding>
                                <ListItemButton
                                    component={Link}
                                    to={page.link}
                                >
                                <ListItemIcon>
                                    <ConnectedTvIcon/>
                                </ListItemIcon>
                                <ListItemText primary={page.name} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Grid>
                <Grid item xs={6}>
                    <Typography align='center' sx={{ margin: 1 }} variant="h5">Departments</Typography>
                    <Divider/>
                    <List>
                        {machinePages.map(page => (
                            <ListItem key={page.name} disablePadding>
                                <ListItemButton 
                                    component={Link}
                                    to={page.link}
                                >
                                <ListItemIcon>
                                    <PrecisionManufacturingIcon/>
                                </ListItemIcon>
                                <ListItemText primary={page.name} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                    <Divider/>
                    <Typography align='center' sx={{ margin: 1 }} variant="h5">Buildings</Typography>
                    <Divider/>
                    <List>
                        {buildings.map(page => (
                            <ListItem key={page.name} disablePadding>
                                <ListItemButton 
                                    component={Link}
                                    to={page.link}
                                >
                                <ListItemIcon>
                                    <BusinessIcon/>
                                </ListItemIcon>
                                <ListItemText primary={page.name} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Grid>
            </Grid>
        </Box>
    )

    return (
        <AppBar style={{ background: '#0e4780', position: 'sticky', padding: 5 }}>
            <Grid container sx={{ display: 'flex' }}>
                <Grid item>
                    <Box sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'center', width: 'fit-content', '&:hover': { cursor: 'pointer', opacity: 0.8 } }}>
                        <img style={{ maxWidth: '80%', height: '70%' }} onClick={() => window.location.replace('/')} src={ Logo } alt="" />
                    </Box>
                    <Box sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'center', width: 'fit-content', '&:hover': { cursor: 'pointer', opacity: 0.8 } }}>
                        <img style={{ maxWidth: '70%', height: '60%', '&:hover': { cursor: 'pointer', opacity: 0.8 } }} onClick={() => window.location.replace('/')} src={ MobileLogo } alt="" />
                    </Box>
                </Grid>
                <Grid item sx={{ display: 'flex' }}>
                    <IconButton
                        size="large"
                        sx={{ display: {xs: 'none', md: 'flex'}, width: 'fit-content', '&:hover': { cursor: 'pointer', opacity: 0.8 } }}
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={toggleMachineMonitoringDrawer('left', true)}
                        color="inherit"
                    >
                        <ConnectedTvIcon/>
                        <Typography sx={{ paddingLeft: 1, display: { xs: 'none', md: 'flex' }, width: 'fit-content' }}>Machine Monitoring</Typography>
                    </IconButton>
                    <IconButton
                        size="medium"
                        sx={{ display: {xs: 'flex', md: 'none'}, width: 'fit-content', '&:hover': { cursor: 'pointer', opacity: 0.8 } }}
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={toggleMachineMonitoringDrawer('left', true)}
                        color="inherit"
                    >
                        <Typography sx={{ fontSize: 14, flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>Machine Monitoring</Typography>
                    </IconButton>
                    <SwipeableDrawer
                        anchor={'left'}
                        open={machineMonitoringButton['left']}
                        onClose={toggleMachineMonitoringDrawer('left', false)}
                        onOpen={toggleMachineMonitoringDrawer('left', true)}
                    >
                        {machineMonitoringList('left')}
                    </SwipeableDrawer>
                </Grid>
                <Grid item sx={{ display: 'flex' }}>
                    <IconButton
                        size="large"
                        sx={{ display: {xs: 'none', md: 'flex'}, width: 'fit-content', '&:hover': { cursor: 'pointer', opacity: 0.8 } }}
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={toggleUtilsDrawer('left', true)}
                        color="inherit"
                    >
                        <AssessmentIcon />
                        <Typography sx={{ paddingLeft: 1, display: { xs: 'none', md: 'flex' } }}>Utility</Typography>
                    </IconButton>
                    <IconButton
                        size="medium"
                        sx={{ display: {xs: 'flex', md: 'none'}, width: 'fit-content', '&:hover': { cursor: 'pointer', opacity: 0.8 } }}
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={toggleUtilsDrawer('left', true)}
                        color="inherit"
                    >
                        <Typography sx={{ fontSize: 14, flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>Utility</Typography>
                    </IconButton>
                    <SwipeableDrawer
                        anchor={'left'}
                        open={utilsButton['left']}
                        onClose={toggleUtilsDrawer('left', false)}
                        onOpen={toggleUtilsDrawer('left', true)}
                    >
                        {utilsList('left')}
                    </SwipeableDrawer>
                </Grid>
                <Grid item sx={{ display: 'flex', marginLeft: 'auto' }}>
                    <IconButton
                        size="large"
                        sx={{ display: {xs: 'none', md: 'flex'}, width: 'fit-content', paddingRight: 3, '&:hover': { cursor: 'pointer', opacity: 0.8 } }}
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={() => {client.logout({redirectUri: window.location.href})}}
                        color="inherit"
                    >
                        <LogoutIcon />
                        <Typography sx={{ paddingLeft: 1, display: { xs: 'none', md: 'flex' }, width: 'fit-content' }}>Sign out</Typography>
                    </IconButton>
                    <IconButton
                        size="medium"
                        sx={{ display: {xs: 'flex', md: 'none'}, '&:hover': { cursor: 'pointer', opacity: 0.8 } }}
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={() => {client.logout({redirectUri: window.location.href})}}
                        color="inherit"
                    >
                        <Typography sx={{ fontSize: 14, flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>Sign out</Typography>
                    </IconButton>
                </Grid>
            </Grid>
        </AppBar>
    )
}