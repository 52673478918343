import { React, useState, useEffect } from 'react'
import { Grid, Typography, Box, Paper, TextField, Button } from "@mui/material/" 
import LoginIcon from '@mui/icons-material/Login'
import UnverifiedUserDialog from '../UnverifiedUser'
import SignOutDialog from './SignOutDialog'
import SignOutConfirmationDialog from './SignOutConfirmationDialog'
import NotLoggedInDialog from './NotLoggedInDialog'
import InactiveRedirect from '../InactiveRedirect'
import IdleUserDialog from '../IdleUser'
import GetRoute from '../../GetRoute'

export default function SignOutVisitor ({ client }) {
    const routeIP = GetRoute(window.location.hostname)
    const protocol = window.location.protocol

    InactiveRedirect(() => setLoginState((prev) => ({...prev, handleIdleUserToggle:  true})))
    const initLoginState = {
        email: "",
        visitorName: "",
        userVerified: null,
        isLoggedIn: null,
        unverifiedVisitorToggle: false,
        signoutVisitorToggle: false,
        signoutConfirmationToggle: null,
        handleIdleUserToggle: false,
        message: "",
        fetchError: null,
        contact: null,
        contactErrorValue: false,
        availableContacts: [],
        loadingButtonToggle: false
    }
    const [loginState, setLoginState] = useState(initLoginState)

    const checkUserExists = async () => {
        try {
            await client.updateToken(5)
            const response = await fetch(`${protocol}//${routeIP}:8000/utils/checkUserExists`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: loginState.email,
                    token: client.token
                })
            })

            if (!response.ok) {
                const error = await response.json()
                throw new Error(error.message)
            }

            const data = await response.json()
            if (data['userVerified']) {
                setLoginState((prev) => ( {...prev, userVerified: data['userVerified'], visitorName: data['firstName'], message: 'Valid user'}))
            } else {
                setLoginState((prev) => ( {...prev, userVerified: data['userVerified'], visitorName: data['firstName'], message: 'Invalid email'}))
            }

        } catch (error) {
            alert(error)
            setLoginState((prev) => ({...prev, fetchError: error}))
        }
    }

    useEffect(() => {
        if (loginState.userVerified) {
            checkUserLogin()
        } else if (loginState.userVerified === false) {
            setLoginState((prev) => ({...prev, unverifiedVisitorToggle: true}))
        }// eslint-disable-next-line
    },[loginState.userVerified])

    const checkUserLogin = async () => {
        try {
            await client.updateToken(5)
            const response = await fetch(`${protocol}//${routeIP}:8000/utils/checkUserLoginStatus`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: loginState.email,
                    token: client.token
                })
            })
            
            if (!response.ok) {
                const error = await response.json()
                throw new Error(error.message)
            }

            const data = await response.json()
            if (data['isLoggedIn']){
                setLoginState((prev) => ({...prev, isLoggedIn: data['isLoggedIn'], signoutVisitorToggle: true}))
            } else {
                setLoginState((prev) => ({...prev, isLoggedIn: data['isLoggedIn']}))
            }

        } catch (error) {
            alert(error)
            setLoginState((prev) => ({...prev, fetchError: error}))
        }
    }

    return (
        <Box sx={{ backgroundColor: '#002233' }}>
            <Grid container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginInline: 'auto', maxWidth: '1920px', minHeight: 'calc(100vh - 63.59px)' }}>
                <Grid item xs={0} sm={12} md={6}>
                    <Box className='apolloLogoBackground' />
                </Grid>
                <Grid item xs={12} sm={12} md={6} sx={{ color: 'white', padding: '2%' }}>
                    <Grid container>
                        <Grid item xs={12} sx={{ marginBottom: 1 }}>
                            <Box>
                                <Paper elevation={4} sx={{ backgroundColor: '#0e4780' }}>
                                    <Typography gutterBottom variant="h4" sx={{ textAlign: 'center', color:'white', padding: .5, margin: 0 }}>Visitor Sign out</Typography>
                                </Paper>
                            </Box>
                        </Grid>
                        <Grid item xs={9.5} sx={{ paddingRight: 1 }}>
                            <Paper sx={{ backgroundColor: 'white', width:'100%' }}>
                                <TextField 
                                    fullWidth 
                                    id="fullWidth" 
                                    label="Email" 
                                    variant="filled" 
                                    name='email'
                                    autoComplete="off"
                                    value={loginState.email} 
                                    onChange={(e) => {setLoginState((prev) => ({...prev, email: e.target.value}))}}
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={2.5} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                sx={{
                                    backgroundColor: '#0e4780',
                                    width: '100%',
                                    color: 'white',
                                    textTransform: 'none',
                                    fontSize:{ sm: 12, md: 14, lg: 16 },
                                    '&:hover': { cursor: 'pointer', backgroundColor: '#0B3866' }
                                }}
                                onClick={async () => {
                                    await checkUserExists()
                                }}
                            >   
                                Sign out
                                <LoginIcon sx={{ paddingLeft: 1, color: 'white' }} />
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            { loginState.unverifiedVisitorToggle ? 
                <UnverifiedUserDialog 
                    client={client} 
                    loginState={loginState}
                    setLoginState={setLoginState}
                    initLoginState={initLoginState}
                /> : <></>
            }
            { loginState.signoutVisitorToggle ? 
                <SignOutDialog 
                    client={client} 
                    loginState={loginState}
                    setLoginState={setLoginState}
                    initLoginState={initLoginState}
                /> : <></>
            }
            { loginState.signoutConfirmationToggle ? 
                <SignOutConfirmationDialog 
                    loginState={loginState} 
                    setLoginState={setLoginState} 
                    initLoginState={initLoginState} 
                /> : <></>
            }
            { loginState.isLoggedIn === false ? 
                <NotLoggedInDialog
                    loginState={loginState} 
                    setLoginState={setLoginState} 
                    initLoginState={initLoginState} 
                /> : <></>
            }
            { loginState.handleIdleUserToggle ?
                <IdleUserDialog
                    state={loginState} 
                    setState={setLoginState} 
                /> : <></>
            }
        </Box>
    )
}