import * as React from 'react'
import { useState } from "react"
import { Typography, Grid, Box, Paper, Divider, Input, Button, Link, FormHelperText } from '@mui/material/'
import LinearProgress from '@mui/material/LinearProgress'
import GetRoute from '../GetRoute'

export default function FaiConverter({ client }) {
    const routeIP = GetRoute(window.location.hostname)
    const protocol = window.location.protocol
    
    //onchange states
    const initialState = {
        userSuppliedExcelFile: null,
        typeError: false,
        fileName: null,
        convertedExcelFile: null,
        linearProgressToggle: false
    }
    const [faiConversionStates, setFaiConversionStates] = useState(initialState)
    
    //onchange event
    const handleFile = (e) => {
        const fileTypes = ['application/vnd.ms-excel','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','text/csv']
        const selectedFile = e.target.files[0]

        if (selectedFile && fileTypes.includes(selectedFile.type)) {
            setFaiConversionStates(prev => ({...prev, fileName: selectedFile.name}))
            setFaiConversionStates(prev => ({...prev, typeError: false}))
            const formData = new FormData()
            formData.append('file', selectedFile)
            setFaiConversionStates(prev => ({...prev, userSuppliedExcelFile: formData}))
            setFaiConversionStates(prev => ({...prev, convertedExcelFile: null}))
        } else {
            alert("Invalid file type. Please select a valid file type.")
            setFaiConversionStates(initialState)
            setFaiConversionStates(prev => ({...prev, typeError: true}))
        }
    }
    
    const onSubmit = async () => {
        setFaiConversionStates(prev => ({...prev, linearProgressToggle: true}))
    
        try {
            const refreshed = await client.updateToken(5)
    
            if (refreshed && faiConversionStates.userSuppliedExcelFile.has('token')) {
                faiConversionStates.userSuppliedExcelFile.delete('token')
                faiConversionStates.userSuppliedExcelFile.append('token', client.token)
            } else if (!faiConversionStates.userSuppliedExcelFile.has('token')) {
                faiConversionStates.userSuppliedExcelFile.append('token', client.token)
            }
            const response = await fetch(`${protocol}//${routeIP}:8000/utils/faiConverter`, {
                method: 'POST',
                body: faiConversionStates.userSuppliedExcelFile
            })
    
            if (!response.ok) {
                const error = await response.json()
                throw new Error(error.message)
            } else {
                const blob = await response.blob()
                const fileReader = new FileReader()
                fileReader.onload = () => {
                    const content = fileReader.result
                    setFaiConversionStates(prev => ({...prev, convertedExcelFile: content}))
                }
                fileReader.readAsDataURL(blob)
                setFaiConversionStates(prev => ({...prev, linearProgressToggle: false}))
            }
            
        } catch (error) {
            alert(error.message)
            setFaiConversionStates(prev => ({...prev, linearProgressToggle: false}))
        }
    }

    const downloadFile = (e) => {
        e.preventDefault()
        const url = faiConversionStates.convertedExcelFile
        const documentAnchor = document.createElement('a')
        documentAnchor.href = url;
        documentAnchor.download = `Updated${faiConversionStates.fileName}` // Set the desired file name
        document.body.appendChild(documentAnchor)
        documentAnchor.click()
        setFaiConversionStates(prev => ({...prev, convertedExcelFile: null}))
        
        // window.URL.revokeObjectURL(url)
    }

    return (
        <Box 
            sx={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'center',
                marginInline: 'auto',
                paddingTop: 5,
                minHeight:'calc(100vh - 329px)',
                maxWidth: '1920px'
            }}
        >
            <Paper elevation={8}>
                {faiConversionStates.linearProgressToggle === true ? (
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box>
                ) : (
                    <Box sx={{ width: '100%', minHeight: '4px' }}/>
                )}
                <Grid container sx={{ justifyContent: 'space-evenly', paddingTop: 2, paddingBottom: 3 }}>
                    <Grid item xs={12} sx={{ margin: 1 }}>
                        <Typography gutterBottom variant="h4" sx={{ textAlign: 'center' }}>
                            FAI to Fair Template Conversion
                        </Typography>
                    </Grid>
                    <Grid item sm={12} md={5.9}>
                        <Grid container direction="column">
                            <Grid item xs={12}>
                                <Typography gutterBottom variant="h5" sx={{ textAlign: 'center' }}>
                                    Select FAI
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                                <Input type='file' onChange={handleFile} error={faiConversionStates.typeError === true}/>
                            </Grid>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', marginBottom: 2 }}>
                                <FormHelperText>FAI must be AS9102 Revision C or B</FormHelperText>
                            </Grid>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'right', margin: 2 }}>
                            {faiConversionStates.typeError === true || faiConversionStates.userSuppliedExcelFile === null ? (
                                <Button
                                    size='large'
                                    variant="contained"
                                    style={{ textTransform: 'none' }}
                                    id="submitButton"
                                    disabled
                                    sx={{
                                        backgroundColor: '#107d23',
                                        width: 120,
                                        color: 'white',
                                        "&:hover": { backgroundColor: "#0a4a15", color: "white" }
                                    }}
                                    onClick={() => {
                                        const button = document.querySelector('#submitButton')
                                        button.disabled = true
                                        setTimeout(() => button.disabled = false, 1000)
                                    }}
                                >
                                    Submit
                                </Button>
                            ):(
                                <Button
                                    size='large'
                                    variant="contained"
                                    id="submitButton"
                                    sx={{
                                        textTransform: 'none',
                                        backgroundColor: '#0e4780',
                                        width: 120,
                                        color: 'white',
                                        "&:hover": { backgroundColor: '#0B3866', color: "white" }
                                    }}
                                    onClick={() => {
                                        onSubmit()
                                        const button = document.querySelector('#submitButton')
                                        button.disabled = true
                                        setTimeout(() => button.disabled = false, 1000)
                                    }}
                                >
                                    Submit
                                </Button>
                            )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider orientation="vertical" flexItem sx={{ mr: '-1px' }}/>
                    <Grid item sm={12} md={5.9}>
                        <Grid container
                            direction="column" 
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Grid item xs={12}>
                                <Typography gutterBottom variant="h5" sx={{ textAlign: 'center' }}>
                                    Fair Template Conversion
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ margin: 2 }}>
                                { faiConversionStates.convertedExcelFile ?
                                    <Link href='#' sx={{ fontSize: 20 }} onClick={downloadFile}>Updated{faiConversionStates.fileName}</Link> :
                                    <Typography sx={{ textAlign: 'center', fontSize: 20 }}>Please select an FAI to convert</Typography>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    )
}
