import { useState, useEffect } from 'react'

const useOnScreen = (ref, rootMargin = '0px') => {
    const [hasBeenVisible, setHasBeenVisible] = useState(false)

    useEffect(() => {
        const observer = new IntersectionObserver(
        ([entry]) => {
            if (entry.isIntersecting) {
            setHasBeenVisible(true)
            }
        },
        { rootMargin }
        )

        if (ref.current) {
        observer.observe(ref.current)
        }

        return () => {
        if (ref.current) {
            observer.unobserve(ref.current)
        }
        }
    }, [ref, rootMargin])

    return hasBeenVisible
};

export default useOnScreen
